/* Common section styles */
.section-title {
  font-size: 1.8rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
}

.section-text {
  font-size: 1.2rem;
  color: #34495e;
  text-align: center;
  margin-bottom: 1.5rem;
}

/* Excel Section Styles */
.excel-section {
  padding: 2rem;
  text-align: center;
}

.excel-animation {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.laptop-icon {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.excel-screen {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.excel-icon {
  color: #27ae60;
}

.excel-cursor {
  width: 10px;
  height: 10px;
  background: #3498db;
  border-radius: 50%;
  position: absolute;
}

/* Branch Achievements Styles */
.branch-achievements {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.branches-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 1rem;
}

.branch-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.branch-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.branch-icon {
  font-size: 2.5rem;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.branch-content {
  flex: 1;
}

.branch-content h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
  font-weight: bold;
}

.branch-content p {
  font-size: 1.1rem;
  color: #4a5568;
  margin-bottom: 0.75rem;
}

.branch-stats {
  font-size: 1.2rem;
  color: #2d3748;
  font-weight: bold;
  padding: 0.5rem 0;
  border-top: 2px dashed rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.achievement-sparkle {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .branches-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .section-title {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  .branch-card {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .branches-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Animation specific styles */
.fade-up {
  animation: fadeUp 0.5s ease-out forwards;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Celebration effects */
.celebration-confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--confetti-color);
  border-radius: 50%;
  animation: confettiFall 4s ease-in infinite;
}

@keyframes confettiFall {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

/* Celebration Section Styles */
.celebration-section {
  padding: 1rem;
  background-color: #1F2937;
  border-radius: 1rem;
  color: #F3F4F6;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.celebration-title {
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: bold;
  margin-bottom: 2rem;
  color: #F3F4F6;
  padding: 0 1rem;
}

.celebration-messages {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.message-card {
  background-color: rgba(96, 165, 250, 0.1);
  border-radius: 0.5rem;
  padding: 1.25rem;
  transition: transform 0.2s;
}

.message-card:hover {
  transform: translateY(-4px);
}

.message-card h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: #60A5FA;
}

.message-card p {
  font-size: 1rem;
  color: #D1D5DB;
  line-height: 1.5;
}

.final-message {
  background-color: rgba(96, 165, 250, 0.15);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin: 1rem;
}

.final-message h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: #F3F4F6;
}

.final-message p {
  font-size: 1.125rem;
  color: #D1D5DB;
  line-height: 1.6;
}

/* Tablet and Desktop Styles */
@media (min-width: 768px) {
  .celebration-section {
    padding: 2rem;
  }

  .celebration-messages {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }

  .message-card {
    padding: 1.5rem;
  }

  .message-card h3 {
    font-size: 1.25rem;
  }

  .message-card p {
    font-size: 1.125rem;
  }

  .final-message {
    padding: 2rem;
    margin: 2rem auto;
    max-width: 800px;
  }

  .final-message h3 {
    font-size: 1.5rem;
  }

  .final-message p {
    font-size: 1.25rem;
  }
}

/* Touch device optimizations for cards */
@media (hover: none) {
  .message-card:hover {
    transform: none;
  }
  
  .message-card:active {
    transform: scale(0.98);
  }
}

.yearly-stats-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 3rem 0;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.stat-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.stat-icon {
  font-size: 2.5rem;
  color: #60A5FA;
  background: rgba(96, 165, 250, 0.1);
  padding: 1rem;
  border-radius: 12px;
}

.stat-content {
  text-align: right;
  flex: 1;
}

.stat-content h3 {
  font-size: 1.2rem;
  color: #E5E7EB;
  margin-bottom: 0.5rem;
}

.stat-content p {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  margin: 0;
}

.stat-subtitle {
  font-size: 1rem;
  color: #9CA3AF;
  display: block;
  margin-top: 0.25rem;
}

.celebration-message {
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
}

.celebration-message h3 {
  font-size: 2rem;
  color: #FCD34D;
  margin-bottom: 1rem;
}

.celebration-message p {
  font-size: 1.25rem;
  color: #E5E7EB;
  line-height: 1.6;
  margin: 0.5rem 0;
}

/* Responsive adjustments */
@media screen and (min-width: 768px) {
  .yearly-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .celebration-title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .yearly-stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .celebration-content {
    padding: 4rem;
  }
}

/* Animation enhancements */
.celebration-content {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.celebration-sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
  animation: sparkle 1.5s linear infinite;
}

@keyframes sparkle {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

/* Navigation Styles */
.navigation-dots {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 100;
  direction: ltr;
}

.nav-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-dot.active {
  background: white;
  transform: scale(1.2);
}

.navigation-arrows {
  position: fixed;
  bottom: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  pointer-events: none;
  z-index: 100;
  direction: ltr;
}

.nav-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  pointer-events: auto;
}

.nav-arrow:hover {
  color: white;
  transform: scale(1.1);
}

.nav-arrow:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.nav-arrow.prev {
  transform: none;
}

.nav-arrow.next {
  transform: none;
}

/* Section wrapper styles */
.section-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  touch-action: pan-y pinch-zoom;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .navigation-arrows {
    padding: 0 1rem;
  }

  .nav-arrow {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  .nav-dot {
    width: 10px;
    height: 10px;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .navigation-arrows {
    opacity: 0.8;
  }

  .nav-arrow {
    background: rgba(255, 255, 255, 0.2);
  }
}

/* Prevent text selection during swipe */
.section-wrapper {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Intro Section Styles */
.intro-section {
  padding: 2rem;
  background-color: #1F2937;
  border-radius: 1rem;
  color: #F3F4F6;
  text-align: center;
}

.intro-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #F3F4F6;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.intro-content {
  margin-bottom: 2rem;
}

.intro-text {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #D1D5DB;
  margin-bottom: 2rem;
}

.intro-highlights {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2.5rem;
}

.highlight-item {
  background-color: rgba(96, 165, 250, 0.1);
  padding: 1.5rem;
  border-radius: 0.75rem;
  text-align: center;
  min-width: 200px;
  border: 1px solid rgba(96, 165, 250, 0.2);
  transition: transform 0.2s ease-in-out;
}

.highlight-item:hover {
  transform: translateY(-4px);
  background-color: rgba(96, 165, 250, 0.15);
}

.highlight-label {
  display: block;
  font-size: 0.875rem;
  color: #9CA3AF;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.highlight-value {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #60A5FA;
  margin-bottom: 0.25rem;
}

.highlight-subvalue {
  display: block;
  font-size: 0.875rem;
  color: #D1D5DB;
}

.graph-container {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  height: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.intro-message {
  font-size: 1.25rem;
  color: #9CA3AF;
  margin-top: 1.5rem;
  line-height: 1.6;
}

/* Branch Achievements Section */
.branch-achievements {
  padding: 2rem;
  background-color: #1F2937;
  border-radius: 1rem;
  color: #F3F4F6;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #F3F4F6;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.achievement-card {
  background-color: rgba(96, 165, 250, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.2s;
}

.achievement-card:hover {
  transform: translateY(-4px);
}

.achievement-icon {
  font-size: 2rem;
  color: #60A5FA;
  margin-bottom: 1rem;
}

.branch-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #F3F4F6;
}

.achievement-details {
  color: #9CA3AF;
}

.recruitments {
  font-size: 1.5rem;
  font-weight: bold;
  color: #60A5FA;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.highlight {
  font-size: 0.875rem;
  color: #D1D5DB;
}

.all-branches-note {
  text-align: center;
  font-size: 1.25rem;
  color: #9CA3AF;
  margin-top: 2rem;
}

/* Celebration Section */
.celebration-section {
  padding: 2rem;
  background-color: #1F2937;
  border-radius: 1rem;
  color: #F3F4F6;
  text-align: center;
}

.celebration-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
  color: #F3F4F6;
}

.celebration-messages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.message-card {
  background-color: rgba(96, 165, 250, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem;
  transition: transform 0.2s;
}

.message-card:hover {
  transform: translateY(-4px);
}

.message-card h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #60A5FA;
}

.message-card p {
  font-size: 1.125rem;
  color: #D1D5DB;
}

.final-message {
  background-color: rgba(96, 165, 250, 0.15);
  border-radius: 0.5rem;
  padding: 2rem;
  margin-top: 2rem;
}

.final-message h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #F3F4F6;
}

.final-message p {
  font-size: 1.25rem;
  color: #D1D5DB;
}

/* Responsive Design */
@media (max-width: 768px) {
  .intro-title {
    font-size: 2rem;
  }

  .intro-text {
    font-size: 1.1rem;
  }

  .intro-highlights {
    flex-direction: column;
    gap: 1rem;
  }

  .highlight-item {
    min-width: unset;
  }

  .highlight-value {
    font-size: 1.25rem;
  }

  .graph-container {
    height: 300px;
    padding: 1rem;
  }
}

/* RTL Support */
[dir="rtl"] .intro-text,
[dir="rtl"] .highlight-item {
  text-align: right;
}

/* Animation Helper Classes */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.slide-up {
  animation: slideUp 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Credit text style */
.credit-text {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
  font-style: italic;
}

/* Guide Promotion Styles */
.guide-promotion {
  margin-top: 3rem;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(96, 165, 250, 0.15) 0%, rgba(52, 211, 153, 0.15) 100%);
  border-radius: 1rem;
  border: 2px solid rgba(96, 165, 250, 0.3);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.guide-promotion::before {
  content: '📚 מדריך';
  position: absolute;
  top: 1rem;
  right: -2rem;
  background: #60A5FA;
  color: white;
  padding: 0.25rem 2.5rem;
  transform: rotate(45deg);
  font-size: 0.875rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.guide-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.promotion-content {
  text-align: center;
  padding: 1rem;
}

.promotion-content h3 {
  font-size: 1.5rem;
  color: #60A5FA;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.promotion-content p {
  color: #E5E7EB;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.cta-button {
  background: linear-gradient(135deg, #60A5FA 0%, #3B82F6 100%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.cta-button:hover {
  background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.cta-button span {
  display: inline-block;
  margin-right: 0.5rem;
  transition: transform 0.3s ease;
}

.cta-button:hover span {
  transform: translateX(-5px);
}

@media (max-width: 768px) {
  .guide-promotion {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }
  
  .promotion-content h3 {
    font-size: 1.25rem;
  }
  
  .promotion-content p {
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }
} 