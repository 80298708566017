@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom base styles */
@layer base {
  html {
    @apply antialiased;
  }
  
  body {
    @apply bg-gray-50;
  }
}

/* Add custom component styles */
@layer components {
  .card {
    @apply bg-white rounded-xl shadow-lg p-6;
  }
  
  .btn {
    @apply px-4 py-2 rounded-lg font-medium transition-all duration-300;
  }
  
  .btn-primary {
    @apply bg-primary text-white hover:bg-primary/90;
  }
}

/* RTL Support */
body {
  direction: rtl;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ensure RTL support for Recharts */
.recharts-wrapper {
  direction: ltr;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.sales-card {
    transition: all 0.3s ease;
    background: linear-gradient(135deg, #fff 0%, #f3f4f6 100%);
    border-right: 5px solid #3b82f6;
    position: relative;
    overflow: hidden;
}

.sales-card::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    pointer-events: none;
}

.success-tip {
    background: linear-gradient(135deg, #dcfce7 0%, #bbf7d0 100%);
    border-right: 5px solid #22c55e;
}

.warning-tip {
    background: linear-gradient(135deg, #fef3c7 0%, #fde68a 100%);
    border-right: 5px solid #f59e0b;
}

.highlight-text {
    background: linear-gradient(120deg, #ffd700 0%, #ffa500 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 640px) {
    .sales-card {
        border-right-width: 4px;
    }
}